import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../../shared/services/helper.service";
import * as i4 from "../../../core/store/store.service";
import * as i5 from "../../../shared/services/modal.service";
export const OBLIGATION_MANAGEMENT = '/registry-server/obligation/search';
export const CREDIT_SERVICE = '/obps-service';
export const OBLIGATION_DETAILS = '/registry-server/obligation/getObligationDetail';
export const ASSESSMENT_RESULT_REPORT = '/obps-service/credits/assessmentResultReports';
export const REFUND_REQUEST = '/obps-service/payment-refund/search';
export const DEADLINE_BY_OBLIGATION_ID = '/obps-service/properties/getByObligationId/';
export const PAYMENT_REFUND_OBLIGATION_BY_ID = '/obps-service/payment-refund/obligation';
export const VALIDATE_DETAILS = '/obps-service/payment-refund/validateAndGetDetailToProceed';
export const PAYMENT_BY_ID = '/obps-service/transaction/getPaymentById/';
export const SUBMIT_PAYMENT_REFUND = '/obps-service/payment-refund/submitPaymentRefund';
export const OBLIGATION_TOWARD_ID_DETAILS = '/payment-refund/id/';
export const COMPLETE_PAYMENT_REFUND = '/obps-service/payment-refund/completePaymentRefund';
export const CANCEL_PAYMENT_REFUND = '/obps-service/payment-refund/cancelPaymentRefund';
export const MARK_PAYMENT_REFUND_IN_PROGRESS = '/obps-service/payment-refund/markPaymentRefundInProgress';
export const DOWNLOAD_REFUND_REQUEST = '/obps-service/payment-refund/downloadPaymentRefundTemplate/';
export const DOWNLOAD_REFUND_REQUEST_PREVIEW = '/obps-service/payment-refund/downloadPaymentRefundSubmittedForm/';
export const BULK_ACTION = '/obps-service/payment-refund/doBulkAction';
export const COMPLETE_CASH_PAYMENT_REFUND = '/obps-service/payment-refund/completeCashPaymentRefund';
export const REJECT_PAYMENT_REFUND = '/obps-service/payment-refund/rejectPaymentRefund';
export const APPROVE_PAYMENT_REFUND = '/obps-service/payment-refund/approvePaymentRefund';
const EEC_PERCENTAGE_IGNORE = [181, 178];
export class ObligationManagementService extends AbstractPaginationService {
    constructor(http, router, helperService, store, modalService) {
        super();
        this.http = http;
        this.router = router;
        this.helperService = helperService;
        this.store = store;
        this.modalService = modalService;
    }
    getObligationtowardsDetailsById(id) {
        const url = `${environment.apiUrl}${CREDIT_SERVICE}${OBLIGATION_TOWARD_ID_DETAILS}${id}`;
        return this.http.post(url, {});
    }
    doAction(uri, payload) {
        return this.http.post(`${environment.apiUrl}${CREDIT_SERVICE}${uri}`, payload);
    }
    getObligationMgntList(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        let path = `${environment.apiUrl}${OBLIGATION_MANAGEMENT}`;
        return this.http.post(path, filterState, { params });
    }
    getRefundRequestLIst(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${REFUND_REQUEST}`, filterState, { params });
    }
    getObligationDetails(obligationId) {
        const url = `${environment.apiUrl}${OBLIGATION_DETAILS}/${obligationId}`;
        return this.http.get(url);
    }
    getAssessmentResultReportData(obligationId) {
        const url = `${environment.apiUrl}${ASSESSMENT_RESULT_REPORT}/${obligationId}`;
        return this.http.get(url);
    }
    openAvailableUnitCredits(codeType, obligationData) {
        this.router.navigate(this.helperService.getTranslatedPath(`/obligation-management/remittance/${codeType}/${obligationData.obligationId}`), { state: { data: obligationData } });
    }
    getPaymentDeadline(obligationId) {
        const url = `${environment.apiUrl}${DEADLINE_BY_OBLIGATION_ID}${obligationId}`;
        return this.http.get(url);
    }
    getPaymentById(id) {
        return this.http.get(`${environment.apiUrl}${PAYMENT_BY_ID}${id}`);
        // return this.http.get<IApp.IPaymentConfirmation>(`${ environment.cra }${ PAYMENT_BY_ID }${id}`);
    }
    getPaymentRefundObligationById(id) {
        return this.http.post(`${environment.apiUrl}${PAYMENT_REFUND_OBLIGATION_BY_ID}/${id}`, {});
    }
    validateAndGetDetailToProceed(payload) {
        return this.http.post(`${environment.apiUrl}${VALIDATE_DETAILS}`, payload);
    }
    submitPaymentRefund(payload) {
        return this.http.post(`${environment.apiUrl}${SUBMIT_PAYMENT_REFUND}`, payload);
    }
    completePaymentRefund(payload) {
        return this.http.post(`${environment.apiUrl}${COMPLETE_PAYMENT_REFUND}`, payload);
    }
    cancelPaymentRefund(payload) {
        return this.http.post(`${environment.apiUrl}${CANCEL_PAYMENT_REFUND}`, payload);
    }
    bulkAction(payload) {
        return this.http.post(`${environment.apiUrl}${BULK_ACTION}`, payload);
    }
    completeCashPaymentRefund(payload) {
        return this.http.post(`${environment.apiUrl}${COMPLETE_CASH_PAYMENT_REFUND}`, payload);
    }
    rejectPaymentRefund(payload) {
        return this.http.post(`${environment.apiUrl}${REJECT_PAYMENT_REFUND}`, payload);
    }
    approvePaymentRefund(payload) {
        return this.http.post(`${environment.apiUrl}${APPROVE_PAYMENT_REFUND}`, payload);
    }
    openExcessEmissioncharge(obligationData) {
        this.router.navigate(this.helperService.getTranslatedPath(`/obligation-management/eec/${obligationData.obligationId}`), { state: { data: obligationData } });
    }
    openMarkRefundAsComplete(obligationData) {
        this.router.navigate(this.helperService.getTranslatedPath(`/obligation-management/mark-refund-as-complete`), { state: { data: obligationData } });
    }
    openRefundRequestForm(obligationData) {
        this.router.navigate(this.helperService.getTranslatedPath(`/obligation-management/refunds-requested-form/${obligationData.obligationId}`), { state: { data: obligationData } });
    }
    markPaymentRefundInProgress(id) {
        return this.http.post(`${environment.apiUrl}${MARK_PAYMENT_REFUND_IN_PROGRESS}`, { id });
    }
    show25Check(data) {
        let show = true;
        EEC_PERCENTAGE_IGNORE.forEach(el => {
            if (data.versionProvision.indexOf(el.toString()) > -1) {
                show = false;
            }
        });
        return show;
    }
    showErrorMessage(message) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message: message,
                },
            ],
            message: message,
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
}
ObligationManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ObligationManagementService_Factory() { return new ObligationManagementService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HelperService), i0.ɵɵinject(i4.StoreService), i0.ɵɵinject(i5.ModalService)); }, token: ObligationManagementService, providedIn: "root" });
