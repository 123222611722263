import { IRawFormBase } from '@core/models/raw-form.types';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';

export const CI_INFO_PART_1: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col-6',
        key: 'feedStock.id',
        id: 'feedStock',
        disabled: false,
        required: true,
        translatePrefix: 'COMMON.feedstockList',
        source: SOURCE_DATA_TYPE.FEED_STOCK,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_1',
        placeholder: 'selectItem',
        hideExpression: 'model.readOnly'
      },
      {
        key: 'feedStock.name',
        type: 'input',
        className: 'col-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_1',
        required: true,
        id: 'feedStock',
        hideExpression: '!model.readOnly'
      },
      {
        key: 'feedstockRegion',
        type: 'input',
        className: 'col-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_2',
        required: true,
        id: 'feedstockRegion',
      },
    ],
  },
];

export const CI_INFO_ENERGY_TABLE_13 = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col',
        key: 'id',
        id: 'id',
        disabled: false,
        required: true,
        source: SOURCE_DATA_TYPE.LOOKUP_TYPE_OF_ENERGY,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_15',
        translatePrefix: 'SUBMISSION_MANAGEMENT_MODULE.ci_info',
        translateOptions: true,
      },
      {
        type: 'input',
        id: 'name',
        key: 'name',
        className: 'col',
        required: true,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_16',
      },
      {
        type: 'input',
        id: 'quantity',
        key: 'quantity',
        className: 'col',
        required: true,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_17',
        customTemplateOptions: {
          type: 'number',
        },
      },
    ],
  },
];

export const CI_INFO_ENERGY_TABLE_30_65 = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'dict-select',
        className: 'col',
        key: 'id',
        id: 'id',
        disabled: false,
        required: true,
        source: SOURCE_DATA_TYPE.LOOKUP_TYPE_OF_ENERGY,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_15',
        translatePrefix: 'SUBMISSION_MANAGEMENT_MODULE.ci_info',
        translateOptions: true,
      },
      {
        type: 'input',
        id: 'name',
        key: 'name',
        className: 'col',
        required: true,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_16',
      },
    ],
  },
];

export const CI_INFO_ENERGY_TABLE_CONFIG_13 = {
  translationPrefix: 'SUBMISSION_MANAGEMENT_MODULE.ci_info',
  paginator: true,
  rowsPerPage: 20,
  dataKey: 'dataKey',
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: 'id',
      sortField: 'id',
      header: 'field_15',
      width: AUTO_WIDTH_FOR_COLUMNS,
      useDropDownTemplate: true,
      dictionary: SOURCE_DATA_TYPE.LOOKUP_TYPE_OF_ENERGY,
    },
    {
      field: 'name',
      sortField: 'name',
      header: 'field_16',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'quantity',
      sortField: 'quantity',
      header: 'field_17',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};

export const CI_INFO_ENERGY_TABLE_CONFIG_30_65 = {
  translationPrefix: 'SUBMISSION_MANAGEMENT_MODULE.ci_info',
  paginator: true,
  rowsPerPage: 20,
  dataKey: 'dataKey',
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: 'id',
      sortField: 'id',
      header: 'field_15',
      width: AUTO_WIDTH_FOR_COLUMNS,
      useDropDownTemplate: true,
      dictionary: SOURCE_DATA_TYPE.LOOKUP_TYPE_OF_ENERGY,
    },
    {
      field: 'name',
      sortField: 'name',
      header: 'field_16',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};

export const CI_IFNO_ENTITY_LIST = [{ workflowAction: 'EDIT', enabled: true, id: null }];
