<div id="review-activities" *ngIf="headerDetails">
    <h2>{{'AGREEMENT_CREDITS.Step.main_title' | translate }}</h2>

    <app-step-header *ngIf="headerDetails && !isOpa" [data]="headerDetails"></app-step-header>


    <p>{{'AGREEMENT_CREDITS.Step_2.message' | translate }}</p>

    <ngb-accordion #accordion [closeOthers]="true">
        <ngb-panel *ngFor="let facility of facilities; let i = index">
            <ng-template ngbPanelHeader let-opened="opened">
                <button ngbPanelToggle class="btn btn-transparent">
                  <fa-icon [icon]="['fas', opened ? 'chevron-down' : 'chevron-up']"></fa-icon>
                  <span class="wcag-visuallyhidden">Toggle</span>
                </button>
                <span class="ml-2">{{facility.name}}</span>

              </ng-template>
              <ng-template ngbPanelContent>

                <div class="container">
                    <div class="container">
                        <div class="row">
                            <p class="col-2" style="font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.facilityId' | translate }}</p>
                            <p class="col-10">{{facility.facilityId}}</p>
                        </div>
                        <div class="value-box">
                                    <div class="row">
                                    <div class="col-sm-12 col-md-6"  *ngIf="hasAddress(facility)">
                                        <app-address-view
                                        [legend]="'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.postalAddress' | translate"
                                        [address]="facility.postalAddress">
                                        </app-address-view>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <app-address-view
                                        [legend]="'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.civicAddress' | translate"
                                        [address]="facility.civicAddress">
                                        </app-address-view>
                                    </div>
                                    </div>
                        </div>
                    </div>

                    <div class="container mt-3">
                        <p>{{'AGREEMENT_CREDITS.Step_2.reviewActivitiesMessage' | translate }}</p>
                    </div>

                    <!--Card 1-->
                    <div class="container mt-3" *ngIf="isActivityCodeERP(facility)">

                        <div class="card mb-2 mx-auto w-100">
                            <div class="card-body">
                                <p [innerHTML]="'AGREEMENT_CREDITS.Step_2.emissionReductionProject' | translate" class="card-title" style="font-weight: bold;"></p>
                            </div>
                        </div>

                    </div>

                    <div class="container mt-3" *ngIf="isActivityCodeBPE(facility)">

                        <div class="card mb-2 mx-auto w-100">
                            <div class="card-body">
                                <p class="card-title" style="font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.bpe' | translate }}</p>
                            </div>
                        </div>

                    </div>

                    <!--Card 2-->
                    <div class="container mt-3" *ngIf="isActivityCodePRODCI(facility)">
                        <div class="card mb-2 mx-auto w-100">
                            <div class="card-body">

                                <p class="card-title" style="font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.productionLowCIFuel' | translate }}</p>

                                <div class="card-text mt-4">
                                    <div class="row">
                                        <p class="col-4" style="text-align: right;font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.productionLowCIFuelMessage' | translate }}</p>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-4" style="text-align: right;">{{'AGREEMENT_CREDITS.Step_2.liquidFuels' | translate }}</div>
                                    <div class="col-8 pl-5">
                                        <div class="card" style="width: 18rem;">
                                            <ul class="list-group list-group-flush">
                                                <li  *ngFor="let fuel of facility.liquidFuelsList;" class="list-group-item">{{fuel}}</li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-4" style="text-align: right;">{{'AGREEMENT_CREDITS.Step_2.gaseousFuels' | translate }}</div>
                                    <div class="col-8 pl-5">
                                        <div class="card" style="width: 18rem;">
                                            <ul class="list-group list-group-flush">
                                                <li  *ngFor="let fuel of facility.gaseousFuelsList;" class="list-group-item">{{showFuel(fuel )}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!--Card 3-->
                    <div class="container mt-3" *ngIf="isActivityCodeFuelStation(facility)">

                        <div class="card mb-2 mx-auto w-100">
                            <div class="card-body">

                                <p class="card-title" style="font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.agreementFSCreateCredits' | translate }}</p>

                                <div class="card-text mt-4">
                                    <div class="row">
                                        <p class="col-4" style="text-align: right;font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.nameOfFuelingStation' | translate }}</p>
                                        <p class="col-8 pl-5"> {{facility.name}}</p>
                                    </div>
                                    <div class="row mt-4">
                                        <p class="col-4" style="text-align: right;font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.fuelTypesAtStation' | translate }}</p>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col-4" style="text-align: right;"></div>
                                    <div class="col-8 pl-5">
                                        <div class="card" style="width: 18rem;">
                                            <ul class="list-group list-group-flush">
                                                <li  *ngFor="let fuel of facility.gaseousFuelsList;" class="list-group-item">{{showFuel(fuel )}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!--Card 4-->
                    <div class="container mt-3 mb-3" *ngIf="isActivityCargingStation(facility)">
                        <div class="card mb-2 mx-auto w-100">
                            <div class="card-body">

                                <p class="card-title" style="font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.agreementToCreateSupplyElec' | translate }}</p>

                                <div class="card-text mt-4">
                                    <div class="row">
                                        <p class="col-4" style="text-align: right;font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.nameOfTheChargingStation' | translate }}</p>
                                        <p class="col-8 pl-5">{{facility.name}}</p>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                    <!--Card 5-->
                    <div class="container mt-3 mb-3" *ngIf="isActivityNRCargingStation(facility)">
                        <div class="card mb-2 mx-auto w-100">
                            <div class="card-body">

                                <p class="card-title" style="font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.agreementToCreateSupplyElec' | translate }}</p>

                                <div class="card-text mt-4">
                                    <div class="row mt-4">
                                        <p class="col-4" style="text-align: justify;font-weight: bold;">{{'AGREEMENT_CREDITS.Step_2.provinceStationLocated' | translate }}</p>
                                        <div class="col-8 pl-5">
                                            <div class="card" style="width: 18rem;">
                                                <ul class="list-group list-group-flush">
                                                    <li  *ngFor="let fuel of facility.liquidFuelsList;" class="list-group-item">{{showFuel(fuel )}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>

            </ng-template>
        </ngb-panel>
    </ngb-accordion>



    <p class="mb-0 mt-3">{{'AGREEMENT_CREDITS.Step_2.note' | translate }}</p>

</div>
