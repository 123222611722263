import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CCR_CONTACT_PERSON_NAME_FLD } from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import { CreditCreationReportsService } from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CIVIC_ADDRESS_FORM_SECTION, POSTAL_ADDRESS_FORM_SECTION } from '@shared/components/stepper/stepper.const';
import {
  APPLICATION_APPROVAL_CARBON_INTENSITY_CONTACT_PERSON,
} from '@shared/components/steps/constants/application-approval-carbon-intensity.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { cloneDeep, isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const SAME_AS_CONTACT_PERSON = 'contactPersonSame';
const SAME_AS_AUTH_OFFICIAL = 'sameAsAuthorizedOfficial';

@Component({
  selector: 'app-contact-person-information-form',
  templateUrl: './contact-person-information-form.component.html',
  styleUrls: ['./contact-person-information-form.component.scss'],
})
export class ContactPersonInformationFormComponent implements OnInit, OnDestroy {

  public _destroy$ = new Subject<any>();

  public visible = true;

  public currentStepObj;

  public authorizedOfficial;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  model;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ContactPersonInformationFormComponent>,
    private service: CreditCreationReportsService,
    private formFactoryService: FormFactoryService,
  ) {
  }

  ngOnInit() {
    this.currentStepObj = this.data.currentStepObj;

    const source = this.data.readOnly
      ? this.currentStepObj.additionalDetailsModel[CCR_CONTACT_PERSON_NAME_FLD]
      : this.currentStepObj.model[CCR_CONTACT_PERSON_NAME_FLD];

    if (source) {
      this.model = {
        ...cloneDeep(source),
        _organizationAddress: cloneDeep(this.service.registrationReportsDetail.postalAddress),
      };
      this.configure();
    } else {
      this.service.getProjectDetails(this.data.projectId).subscribe(project => {
        this.model = {
          ...cloneDeep(project[CCR_CONTACT_PERSON_NAME_FLD]),
          _organizationAddress: cloneDeep(this.service.registrationReportsDetail.postalAddress),
        };

        this.configure();
      });
    }

  }

  public save(): void {
    this.formFactoryService.markFormGroupDirty(this.form);
    if (this.form.valid) {
      this.dialogRef.close(this.model);
    }
  }

  private subscribeCheckboxChange() {
    this.formFactoryService.event_callback
      .pipe(takeUntil(this._destroy$))
      .subscribe((field: any) => {
      if (field.key === SAME_AS_CONTACT_PERSON && this.model[SAME_AS_CONTACT_PERSON]) {

        this.model = {
          ...cloneDeep(this.service.registrationReportsDetail.contactPerson),
          [SAME_AS_CONTACT_PERSON]: this.model[SAME_AS_CONTACT_PERSON],
          [SAME_AS_AUTH_OFFICIAL]: !this.model[SAME_AS_CONTACT_PERSON],
        };
        this.disableForm();

      } else if (field.key === SAME_AS_AUTH_OFFICIAL && this.model[SAME_AS_AUTH_OFFICIAL]) {
        this.model = {
          id: null,
          ...cloneDeep(this.authorizedOfficial),
          [SAME_AS_CONTACT_PERSON]: !this.model[SAME_AS_AUTH_OFFICIAL],
          [SAME_AS_AUTH_OFFICIAL]: this.model[SAME_AS_AUTH_OFFICIAL],
        };
        this.disableForm();
      }

      if (!this.model[SAME_AS_AUTH_OFFICIAL] && !this.model[SAME_AS_CONTACT_PERSON]) {
        !this.formDisabled && this.form.enable();
        this.model.id = null;
        this.model.civicAddress && (this.model.civicAddress.id = null);
        this.model.postalAddress && (this.model.postalAddress.id = null);
      }
      this.model._organizationAddress = cloneDeep(this.service.registrationReportsDetail.postalAddress);
    });
  }

  private disableForm() {
    this.form.disable();
    this.form.get(SAME_AS_CONTACT_PERSON) && this.form.get(SAME_AS_CONTACT_PERSON).enable();
    this.form.get(SAME_AS_AUTH_OFFICIAL) && this.form.get(SAME_AS_AUTH_OFFICIAL).enable();
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  private configure() {
    this.authorizedOfficial = {
      ...this.service.registrationReportsDetail.authorizedOfficial,
      name: this.service.registrationReportsDetail.authorizedOfficial.fullName,
    };
    this.fields = this.formFactoryService.configureForm(APPLICATION_APPROVAL_CARBON_INTENSITY_CONTACT_PERSON);
    const postalAddress = {
      ...POSTAL_ADDRESS_FORM_SECTION,
      optionalPoBox: true,
      sameAsOrganization: {
        hide: false,
        changeExpr: `this.disableForm($event, field); this.cloneFormGroup($event, field, '_organizationAddress', 'postalAddress')`,
      },
    };

    this.fields.push(this.formFactoryService.configurFormPostWithCivicAddress(postalAddress, CIVIC_ADDRESS_FORM_SECTION, '_organizationAddress')[0]);
    this.subscribeCheckboxChange();
    setTimeout(() => this.formDisabled && this.form.disable());
  }

  get formDisabled(): boolean {
    return this.data.readOnly;
  }

  ngOnDestroy() {
    this.service.clearCallMethod();
    this._destroy$.next();
    this._destroy$.complete();
  }

}
